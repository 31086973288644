<template>
  <div class="monitor">
    <img class="logo" src="~@/assets/img/monitor/monitorTitle.png"/>
    <div class="content">
      <car
        :connect-tip="connectTip"
        :floor="g01.floor"
        :run="g01.run"
        :dir="g01.dir"
        :door="g01.door"
      ></car>
      <div style="flex:1;margin-left: 10px">
        <table class="vm-table">
          <tr>
            <th>所属项目</th>
            <td>
              {{elevator.realEstateName}}
              <el-button style="color: white" type="text" @click="$refs.elevatorDetail.open(elevator.id)">详情</el-button>
            </td>
          </tr>
          <tr>
            <th style="width: 90px">电梯名称</th>
            <td>
              <el-select :value="elevator.id" style="width: 150px" @change="changeElevator">
                <el-option v-for="item in elevatorList" :key="item.id" :label="item.buildingName" :value="item.id"></el-option>
              </el-select>
            </td>
          </tr>
          <tr>
            <th>注册代码</th>
            <td>{{elevator.regCode}}</td>
          </tr>
          <tr>
            <th>协议来源</th>
            <td>{{elevator.elevatorIot.iotProtocolSourceDesc}}</td>
          </tr>

          <tr>
            <th>终端编号:端口号</th>
            <td>{{elevator.elevatorIot.iotCode}}</td>
          </tr>
          <tr>
            <th>出厂编号</th>
            <td>{{elevator.factoryCode}}</td>
          </tr>
        </table>

        <table class="vm-table data-point">
          <tr>
            <th colspan="2">数据点</th>
          </tr>
          <tr>
            <td>服务模式<span>{{getDict("mode")}}</span></td>
            <td>运行状态<span>{{getDict("run")}}</span></td>ƒ
          </tr>
          <tr>
            <td>运行方向<span>{{getDict("dir")}}</span></td>
            <td>速度(m/s)<span>{{g01.speed}}</span></td>
          </tr>
          <tr>
            <td>门状态<span>{{getDict("door")}}</span></td>
            <td>是否有人<span>{{getPerson(g01.person)}}</span></td>
          </tr>
          <tr>
            <td>累计运行时间(分钟)<span>{{g04.runTime}}</span></td>
            <td>累计运行次数<span>{{g04.runCount}}</span></td>
          </tr>
          <tr>
            <td>累计运行距离<span>{{g04.runningDistance}}</span></td>
            <td>电梯开门次数<span>{{g04.doorOpenCount}}</span></td>
          </tr>
          <tr>
            <td>满载<span>{{g01.LoadPercentage?'满载':'未满载'}}</span></td>
            <td>超载<span>{{g01.fullLoad?'超载':'未超载'}}</span></td>
          </tr>
          <tr>
            <td>故障码<span>{{g01.faults}}</span></td>
            <td>数据时间<span>{{g01.time}}</span></td>
          </tr>
<!--          <tr>-->
<!--            <td colspan="2">网络延迟<span>{{g01.delay}}ms</span></td>-->
<!--          </tr>-->
        </table>
      </div>
    </div>
    <elevator-detail ref="elevatorDetail"></elevator-detail>
  </div>
</template>

<script>

  import Mqtt from "@/util/mqtt";
  import Car from "./LiftMonitorCar";
  import {mutations} from "@/store/store";
  import ElevatorDetail from "@/views/elevator/ElevatorDetail";
  export default {
    components: {Car,ElevatorDetail},
    props:{
      query:Object,
    },
    data() {
      return {
        connectTip: "连接中...",
        elevator: {
          elevatorUsage:{},
          elevatorIot:{},
        },
        floors:[],
        iotDevice: {},
        monitorDicts: {},
        g01: {},
        g04: {},
        mqttClient: null,
        heartInterval: 0,
        timer:null,
        elevatorList:[

        ],
      };
    },
    watch:{
      query:{
        handler(params) {
          for (let [key,value] of Object.entries(params)) {
            if (key==="elevatorId") {
              if (this.mqttClient) {
                this.mqttClient.end();
              }
              this.getElevator(value);
            }
          }
        },
        deep:true,
        // immediate:true,
      },
    },
    mounted() {
      const os = localStorage.getItem("os");
      let elevatorId;
      if (os==="new") {
        elevatorId=this.query.elevatorId;
      }else {
        elevatorId = this.$route.query.elevatorId;

      }
      // this.elevatorId = elevatorId
      this.getElevator(elevatorId);
      mutations.getMonitorDicts().then(result => {
        this.monitorDicts = result;
      });
    },
    destroyed() {
      if (this.mqttClient) {
        this.mqttClient.end();
      }
    },
    methods: {
      getElevator(elevatorId) {
        this.elevator.id = elevatorId;
        this.$http.get(`elevator-archive/${elevatorId}`).then(data => {
          this.elevator = data;
          this.floors=data.floors instanceof Array ? data.floors:[];
          this.getElevatorList(data.realEstateId);
          if (this.elevator.elevatorIot) {
            if(!this.elevator.elevatorIot.online) {
              this.connectTip = "该电梯暂未在线";
            }
            this.initMqtt(this.elevator.elevatorIot.iotCode);
            this.startMonitor();
          } else {
            this.connectTip = "该电梯不支持远程监测";
          }
        });
      },
      getElevatorList(id) {
        this.$http.get("/elevator/archive/iot/page",{realEstateId:id,pageSize:1000,online:true}).then(data=>{
          this.elevatorList = data.records;
        });
      },
      changeElevator(item) {
        this.elevator.id = item;
        if (this.mqttClient) {
          this.mqttClient.end();
          this.getElevator(item);

        }else {
          this.getElevator(item);
        }
      },
      initMqtt(deviceNo) {
        let that = this;
        this.locked=false;
        let mqtt = new Mqtt();
        // let arr =[];
        // let tempArr=[];
        let g01Topic = `giot/${deviceNo}/gateway/g01`;//g01电梯状态数据
        let g04Topic = `giot/${deviceNo}/gateway/g04`;
        mqtt.connect({
          onConnect(client) {
            that.mqttClient = client;
            client.subscribe([g01Topic,g04Topic]);
            that.connectTip = "等待数据";
          },
          onMessage(topic, message) {
            that.connectTip = "";
            // if (this.timer) {
            //
            //   clearTimeout(this.timer);
            //   this.timer=setTimeout(()=>{
            //     window.alert("长时间未接受到消息，请刷新页面");
            //     clearTimeout(this.timer);
            //   },30000);
            // }else {
            //   this.timer=setTimeout(()=>{
            //     window.alert("长时间未接受到消息，请刷新页面");
            //     clearTimeout(this.timer);
            //   },30000);
            // }
            if (topic === g01Topic) {
              const time = new Date() - new Date(message.time);
              message.delay = time;
              that.floors.forEach(item=>{

                if (item.name===message.floor) {

                  message.floor=item.displayName;
                }
              });
              that.g01 = message;
              // if (arr.length<=1) {
              //   arr=JSON.parse(JSON.stringify(arr.concat(tempArr)));
              //
              //   tempArr=[];
              //
              // }
              // tempArr.push(message);
              // if (arr.length>1) {
              //   const formatArr= JSON.parse(JSON.stringify(arr));
              //   for (let i = 1; i < arr.length; i++) { //如果楼层相同或者只差1，则不延迟
              //     if (arr[i].floor===arr[i-1].floor||Math.abs(parseInt(arr[i].floor,10)-parseInt(arr[i-1].floor,10))===1) {
              //       that.g01=formatArr.shift();
              //       // that.g01 = arr[i-1];
              //     }else {
              //       if (parseInt(arr[i].floor,10)>parseInt(arr[i-1].floor,10)) {
              //         let count = Math.abs(parseInt(arr[i].floor,10)-parseInt(arr[i-1].floor,10));
              //         formatArr.shift();
              //
              //
              //         that.g01 = arr[i-1];
              //
              //
              //         for (let j=1;j<count;j++) {
              //           (function(j) {
              //             setTimeout(function() {
              //               this.g01=arr[i-1];
              //               this.g01.floor=arr[i-1].floor+j;
              //             }, 300*(j+1));
              //           })(j);
              //         }
              //       }else {
              //         let count = Math.abs(parseInt(arr[i].floor,10)-parseInt(arr[i-1].floor,10));
              //         formatArr.shift();
              //         that.g01 = arr[i-1];
              //
              //         for (let j=1;j<count;j++) {
              //           (function(j) {
              //             setTimeout(function() {
              //               this.g01=arr[i-1];
              //               this.g01.floor=arr[i-1].floor-j;
              //             }, 300*(j+1));
              //           })(j);
              //         }
              //       }
              //     }
              //   }
              //   arr=formatArr;
              // }
            } else if (topic === g04Topic) {

              const time = new Date() - new Date(message.time);
              message.delay = time;

              that.g04 = message;
            }
          },
          onError(err) {
            that.connectTip = err;
          },
        });
      },
      getDict(name) {
        return this.$oc(() => this.monitorDicts[name][this.g01[name]]);
      },
      getPerson(person) {
        if(person>0) {
          return person;
        }else if(person==0) {
          return "无人";
        }else if(person == -2) {
          return "有人但人数未知";
        }else {
          return "未知";
        }
      },

      startMonitor() {
        this.$http.get(`elevator-iot/monitor/${this.elevator.elevatorIot.iotCode}/start`).then(() => {
          console.log("startMonitor成功");
          // clearInterval(this.heartInterval);
          // this.heartInterval = setInterval(() => {
          //   this.heartbeat();
          // }, 120000);
        });
      },
      heartbeat() {
        this.$http.get(`elevator-iot/monitor/${this.elevator.elevatorIot.iotCode}/heartbeat`).then(() => {
          console.log("心跳请求成功");
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
.monitor {
  background-color: black;
  height: 100%;
  min-height: 700px;
}

.logo {
  display: block;
  margin: 0 auto;
}

.content {
  display: flex;
  max-width: 1000px;
  margin: 0 auto;
}

table {
  width: 100%;

  th {
    border: 1px solid #000;
    color: white;
    background-color: #202020;
  }

  td {
    border: 1px solid #000;
    color: white;
    background: #181818;
  }
}

.data-point {
  margin-top: 8px;

  td {
    padding: 9px 15px;
    text-align: left;

    span {
      float: right;
    }
  }

  tr:nth-child(odd) {
    td {
      background: #121212;
    }
  }
}
</style>
